export default async function reportGenerate(lambdalink: string, officeName: string) {
  try {
    const result = await fetch(lambdalink, {
      method: "GET",
    });
    const blob = await result.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Report_${officeName}_${new Date().toJSON().slice(0, 10)}.xlsx`);
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode?.removeChild(link);
    return true;
  } catch (error) {
    console.log("failed to generate report", error);
    return false;
  }
}
