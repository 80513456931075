import React, { createContext, useContext, useState } from "react";

type PatientContextType = {
  patientName: string;
  setPatientName: React.Dispatch<React.SetStateAction<string>>;
};

const PatientContext = createContext<PatientContextType>({
  patientName: "",
  setPatientName: () => undefined,
});

const usePatientContext = (): PatientContextType => {
  return useContext(PatientContext);
};

const PatientProvider = (props: { children: React.ReactNode }) => {
  const [patientName, setPatientName] = useState<string>("");

  return (
    <PatientContext.Provider value={{ patientName, setPatientName }}>
      {props.children}
    </PatientContext.Provider>
  );
};

export { usePatientContext, PatientProvider };
