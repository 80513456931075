import React, { useState } from "react";

import { trpc } from "@calcom/trpc";
import { Button, Dialog, DialogContent, Select } from "@calcom/ui";

import reportGenerate from "@lib/reportGenerate";

import { useOfficeContext } from "@components/navbar";

type UploadReportDialogProps = {
  open: boolean;
  handler: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ReportDialog = ({ open, handler }: UploadReportDialogProps) => {
  const [loading, setLoading] = useState(false);
  const { currentOffice } = useOfficeContext();
  const { data } = trpc.viewer.office.getReportLink.useQuery(
    { officeId: Number(currentOffice) },
    { enabled: !!currentOffice }
  );

  return (
    <div>
      <Dialog open={open} onOpenChange={handler}>
        <DialogContent className="w-full !max-w-[750px] p-5 text-gray-600">
          <div>
            <div className="mb-4 flex items-center justify-between rounded-xl border border-gray-200 bg-white px-5 py-4">
              <p className="text-base font-medium text-gray-800">Select a Report</p>
              <div className="w-full md:w-80">
                <Select
                  instanceId="upload"
                  placeholder="Select Report"
                  options={[{ label: "General Report", value: "general-report" }]}
                  value={{ label: "General Report", value: "general-report" }}
                />
              </div>
            </div>
            <div className="mt-4 flex justify-end gap-4">
              <Button
                color="secondary"
                className="border bg-white text-black hover:bg-white hover:text-black"
                disabled={!data}
                loading={loading}
                onClick={async () => {
                  if (data) {
                    setLoading(true);
                    await reportGenerate(data.link, data.name);
                    setLoading(false);
                  }
                }}>
                Generate Report
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
