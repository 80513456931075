export type Brands =
  | "UNKNOWN"
  | "DINERS_CLUB_INTERNATIONAL"
  | "DANKORT"
  | "MIR"
  | "TROY"
  | "UATP"
  | "CHINA_T_UNION"
  | "CHINA_UNION_PAY"
  | "AMERICAN_EXPRESS"
  | "VERVE"
  | "RUPAY"
  | "DISCOVER"
  | "JCB"
  | "MASTERCARD"
  | "INTERPAYMENT"
  | "INSTAPAYMENT"
  | "MAESTRO"
  | "VISA"
  | "LANKAPAY"
  | "DINERS_CLUB";

const logoByBrand = {
  UNKNOWN: "/card-brands/non-brand.svg",
  DINERS_CLUB_INTERNATIONAL: "/card-brands/non-brand.svg",
  DANKORT: "/card-brands/non-brand.svg",
  MIR: "/card-brands/non-brand.svg",
  TROY: "/card-brands/non-brand.svg",
  UATP: "/card-brands/non-brand.svg",
  CHINA_T_UNION: "/card-brands/non-brand.svg",
  CHINA_UNION_PAY: "/card-brands/non-brand.svg",
  AMERICAN_EXPRESS: "/card-brands/american-express.svg",
  VERVE: "/card-brands/non-brand.svg",
  RUPAY: "/card-brands/non-brand.svg",
  DISCOVER: "/card-brands/discover.svg",
  JCB: "/card-brands/non-brand.svg",
  MASTERCARD: "/card-brands/mastercard.svg",
  INTERPAYMENT: "/card-brands/non-brand.svg",
  INSTAPAYMENT: "/card-brands/non-brand.svg",
  MAESTRO: "/card-brands/non-brand.svg",
  VISA: "/card-brands/visa.svg",
  LANKAPAY: "/card-brands/non-brand.svg",
  DINERS_CLUB: "/card-brands/non-brand.svg",
  BANK: "/card-brands/bank.svg",
};

export const getCardBrand = (cardString: string) => {
  const brand = cardString.split(":")[0].trim() as Brands;
  let cardNumber;
  let logo;

  if (cardString.split(":").length > 1) {
    logo = logoByBrand[brand] || logoByBrand.UNKNOWN;
    cardNumber = cardString.split(":").pop()?.trim().match(/\d+/g)?.join("") || "";
  } else {
    logo = logoByBrand.BANK;
    cardNumber = cardString.match(/\d+/g)?.join("") || "";
  }

  return {
    logo,
    cardNumber: "Last 4 : " + cardNumber,
  };
};
