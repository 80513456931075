import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { trpc } from "@calcom/trpc/react";
import { Button, Form, Label, Select, showToast, Switch, TextAreaField, TextField } from "@calcom/ui";

import { formatNumber, formatPhoneNumber } from "@lib/practice/helper";

import { DollarIcon, MailIcon, MobilePhoneIcon } from "@components/Icons";

type FormValues = {
  amount: number;
  email: string;
  phone: string;
  onEmail: boolean;
  onText: boolean;
  message: string;
  subject: string;
};

type Props = {
  patientId?: number;
  officeId: number;
};

export default function PaymentRequest({ patientId, officeId }: Props) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [patient, setPatient] = useState<number | undefined>(patientId);

  const form = useForm<FormValues>({
    defaultValues: {
      amount: 0,
      email: "",
      phone: "",
      onEmail: true,
      onText: true,
      subject: "We're kindly requesting payment",
    },
    resolver: zodResolver(
      z
        .object({
          amount: z.number().min(0.01),
          email: z.string().email().or(z.literal("")),
          phone: z.string().or(z.literal("")),
          onEmail: z.boolean(),
          onText: z.boolean(),
          message: z.string().min(1, "Required"),
          subject: z.string().min(1, "Required"),
        })
        .superRefine((values, ctx) => {
          if (values.onEmail && !values.email) {
            ctx.addIssue({
              message: "Please enter valid email address",
              code: z.ZodIssueCode.custom,
              path: ["email"],
            });
          }
          if (values.onText && !values.phone) {
            ctx.addIssue({
              message: "Please enter valid phone number",
              code: z.ZodIssueCode.custom,
              path: ["phone"],
            });
          }
          if (!values.onText && !values.onEmail) {
            ctx.addIssue({
              message: "Either phone or email should be filled in.",
              code: z.ZodIssueCode.custom,
              path: ["email"],
            });
          }
        })
    ),
  });

  const { register, formState, control, setValue, watch } = form;

  const watchShowAmount = watch("amount");
  const watchOnEmail = watch("onEmail");
  const watchOnText = watch("onText");

  const sendRequest = trpc.viewer.patients.sendNotification.useMutation({
    onSuccess: (data) => {
      setLoading(false);
      if (data && data.email.on) {
        showToast(data.email.status ? "Email sent" : "Email failed", data.email.status ? "success" : "error");
      }
      if (data && data.sms.on) {
        showToast(data.sms.status ? "SMS sent" : "SMS failed", data.sms.status ? "success" : "error");
      }
      if (!data) {
        showToast("Error occured", "error");
      }
    },
    onError: (e) => {
      setLoading(false);
      showToast("Error occured", "error");
    },
  });

  const { data: patientOptions } = trpc.viewer.patients.getNameList.useQuery({
    patientName: debouncedSearchQuery,
    patientId,
    officeId,
  });

  const { data: singlePatient } = trpc.viewer.patients.getById.useQuery(
    {
      billingOfficeId: officeId,
      patientId: Number(patient),
    },
    { enabled: !!patient }
  );

  const handleSubmit = (data: FormValues) => {
    if (!patient) {
      showToast("Please select patient", "error");
      return;
    }
    setLoading(true);
    sendRequest.mutate({
      ...data,
      billingOfficeId: officeId,
      patientId: patient,
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(search);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    setValue("amount", singlePatient?.balance || 0);
    setValue("email", singlePatient?.email || "");
    setValue("phone", formatPhoneNumber(singlePatient?.phoneNumbers[0]?.number || ""));
    setValue("onEmail", singlePatient?.email ? true : false);
    setValue("onText", singlePatient?.phoneNumbers[0]?.number ? true : false);
  }, [singlePatient]);

  useEffect(() => {
    if (watchShowAmount >= 0)
      setValue(
        "message",
        `Hi ${singlePatient?.firstName || "Patient"} ${
          singlePatient?.lastName || "Name"
        }, please pay your balance of $${watchShowAmount} at your earliest convenience. Thank you!`
      );
  }, [watchShowAmount, singlePatient]);

  return (
    <div>
      <Form className="w-full" form={form} handleSubmit={handleSubmit}>
        <div className="space-y-3">
          <div className="mt-6 flex flex-col gap-5">
            <div className="">
              <Label>Customer</Label>
              <div className="relative w-full">
                <Select
                  placeholder="Select a Patient..."
                  className="rounded-xl"
                  isDisabled={!!patientId}
                  value={patientOptions?.find((p) => p.value === patient)}
                  options={patientOptions}
                  onInputChange={(e) => setSearch(e)}
                  onChange={(option) => {
                    if (option) {
                      setPatient(option.value);
                    }
                  }}
                />
              </div>
            </div>
            <div className="">
              <Label>Amount</Label>
              <TextField
                type="number"
                className="relative rounded-lg"
                label=""
                step={0.01}
                min={0}
                addOnLeading={<DollarIcon />}
                {...register("amount", { valueAsNumber: true })}
              />
            </div>
            <div className="">
              <Controller
                name="onEmail"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex items-center justify-between">
                    <Label className="m-0 text-gray-600">Email Address</Label>
                    <Switch label="" checked={value} onCheckedChange={onChange} />
                  </div>
                )}
              />
              <div className="relative">
                <TextField
                  label=""
                  disabled={!watchOnEmail}
                  className="w-full"
                  addOnLeading={<MailIcon />}
                  {...register("email")}
                />
              </div>
            </div>
            <div className="">
              <Controller
                name="onText"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex items-center justify-between">
                    <Label className="m-0 text-gray-600">Text Message</Label>
                    <Switch label="" checked={value} onCheckedChange={onChange} />
                  </div>
                )}
              />
              <div className="relative">
                <TextField
                  label=""
                  disabled={!watchOnText}
                  className="w-full"
                  addOnLeading={<MobilePhoneIcon />}
                  {...register("phone")}
                  placeholder="(555) 555-5555"
                  onChange={(e) => setValue("phone", formatPhoneNumber(e.target.value))}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 md:col-span-3">
              <TextField
                label="Subject Line"
                className="w-full"
                disabled={!watchOnEmail}
                {...register("subject")}
              />
            </div>
            <div className="flex flex-col gap-2 md:col-span-1">
              <TextAreaField label="Message Content" rows={5} className="w-full" {...register("message")} />
            </div>
            <Button
              className="flex justify-center bg-gray-900 text-white hover:bg-gray-900 hover:text-white"
              type="submit"
              loading={loading}>
              Send ${formatNumber(watchShowAmount, "balance")} Payment Request
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}
